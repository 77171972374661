import React, { useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { viewportSizes } from '@tymate/margaret';
import { AvatarAndName } from 'components';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useWindowSize } from 'react-use';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';

const Carousel = styled.div`
  max-width: 100vw;
  overflow: hidden;
`;

const CarouselStyles = createGlobalStyle`
  .carousel__slider-tray-wrapper {
    max-width: 100vw;
  }

  .carousel__dot-group {
    display: flex;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  .carousel__inner-slide {
    padding: ${({ theme }) => theme.spacing(0.5)};
    display: flex;
    justify-content: center;
  }

  .carousel__dot {
    appearance: none;
    cursor: pointer;
    border: 0;
    background-color: ${({ theme }) => theme.separator};
    padding: ${({ theme }) => theme.spacing(0.25)};
    border-radius: 100%;

    + .carousel__dot {
      margin-left: ${({ theme }) => theme.spacing(0.5)};
    }
  }

  .carousel__dot--selected {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const LawyerCarousel = ({ data }) => {
  const { width } = useWindowSize();
  const isMobile = width < viewportSizes.tablet;
  const isTablet = width < viewportSizes.desktop;
  const isDesktop = width >= viewportSizes.desktop;
  const wrapperRef = useRef();
  const carouselRef = useRef();

  return (
    <Carousel ref={wrapperRef} dragEnabled={!isDesktop || data.length > 5}>
      <CarouselStyles />

      <CarouselProvider
        naturalSlideWidth={width}
        naturalSlideHeight={380}
        totalSlides={data.length}
        visibleSlides={isMobile ? 1 : isTablet ? 3 : 5}
        ref={carouselRef}
        infinite
        isIntrinsicHeight
        isPlaying={!isDesktop || data.length > 5}
      >
        <Slider>
          {data.map(({ node }, index) => (
            <Slide index={index} key={index}>
              <AvatarAndName user={node} />
            </Slide>
          ))}
        </Slider>

        {(!isDesktop || data.length > 5) && <DotGroup />}
      </CarouselProvider>
    </Carousel>
  );
};

const LawyerCarouselToggler = ({ data }) => {
  const isServer = typeof window === 'undefined';

  if (!isServer) {
    return <LawyerCarousel data={data} />;
  }

  return null;
};

export default LawyerCarouselToggler;
